import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Error = () => {
  return (
  <Layout>
    <h1>Error Page</h1>
    
    

  </Layout>
  )
}

export default Error
